/*

    author          : creenx rodriguez
    date created    : 2024-04-21 1330H
    description     : main css style

*/


body {
    
    padding: 0!important;
    margin: 0!important;
    background-color: #ffffff;
    background: url('../img/bg-builging.png');
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    

}


.main-wrapper {

    width: 100%!important;
    height: auto!important;
    min-height: 100vmin!important;
    padding: 0!important;
    margin: 0!important;
    


    .header-wrapper {
        position: fixed;
        z-index: 99999999;
        top: 0!important;
        left: 0!important;
        width: 0px!important;
        height: auto;
        background-color: transparent!important;
        margin: 0!important;
        padding: 0!important;
        
        .temp-logo-container {
            position: relative;
            width: 100vw!important;
            min-width: 100vw!important;
            height: auto;
            padding: 30px 5%!important;

            .header-logo {
                display: block!important;
                height: 120px;
                margin: 0 auto!important;
            }

        }
    }

    .content-wrapper {
        position: relative;
        width: 100vw!important;
        min-width: 100vw;
        max-width: 100vw;
        height: auto;
        min-height: 100vmin!important;
        margin: 0;
        padding: 0;

        .fullwidth {
            
            width: 100vw!important;
            height: 100vh!important;
            min-height: 100vmin!important;
            margin: 0;
            padding: 0!important;
            margin-top: 50px;

            #tsparticles {
                position: absolute;
                top: 0!important;
                left: 0 !important;
                right:0!important;
                min-height: 100vh!important;
            }

            .page-wrapper {
                position: relative;
                width: 100vw!important;
                min-width: 100vw!important;
                min-height: 100vmin!important;

                .counter-container {
                    position: relative;
                    width: 100vw!important;
                    height: auto!important;
                    min-height: 150vh!important;
                    background: url('../img/mp-big-red.png');
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                   
                    .mp-container {
                        position: absolute;
                        top:50%;
                        left: 0%;
                        transform: translate(0%,-40%);
                        width: 300px;

                        .megaphone {
                            height: 200px!important;
                        }

                    }

                    .counter {
                        position: absolute;
                        top: 50%;
                        right: 0%;
                        width: 50%;
                        transform: translate(0%, -50%);
                        height: auto;
                        padding: 50px;

                        h1 {
                            font-size: 5rem;
                            color: #ffffff;
                        }

                        .expired-notice {
                            padding: 2rem;
                            width: auto;

                            span {
                                font-size: 3rem;
                                color: #ffffff;
                            }

                            p {
                                font-size: 2rem;
                                color: #ffffff;
                            }
                        }

                        .show-counter {
                            width: auto;
                            height: auto;

                            .countdown {
                                line-height: 1.25rem;
                                padding: 0 0.75rem;
                                align-items: center;
                                display: flex;
                                flex-direction: column;
                                border: 2px solid #ffffff;

                                p {
                                    margin: 0;
                                }
                            }

                        }
                    }

                    
                }

                .page-article {
                    width: 100vw;
                    padding: 80px 20% 80px 20%!important;
                    text-align: center;

                    h2 {
                        font-size: 2rem;
                        color: #70787C;
                        letter-spacing: 2px;
                        line-height: 5rem;
                    }

                    span {
                        font-size: 2rem;
                        color: #000000;
                        letter-spacing: 1px;
                        float: right;
                        vertical-align: middle;
                        text-align: left;
                    }

                    .qr-code {
                        float: left;
                        width: 150px;
                    }
                    
                }
            }

            .contact-page {
                position: relative;
                width: 100vw;
                padding: 100px 20% 80px 20%!important;
                text-align: center;
                margin: 0!important;
                
                .white-overlay {
                    z-index: -1;
                    position: absolute;
                    top: 0;
                   
                    right: 0;
                    width: 100vw;
                    height: 100%!important;
                    background-color: #ffffff;
                    opacity: 0.4;

                }

                .contact-container {
                    z-index: 999999;
                    width: 90%!important;
                    height: auto!important;
                    text-align: center;

                    p {
                        margin-bottom: 100px;
                    }

                    .contact-form {
                        padding: 0 15%;

                        input[type="text"], input[type="email"] {
                            border-radius: 0;
                            border-top: 0;
                            border-right: 0;
                            border-left: 0;
                            padding-top: 20px;
                            border-bottom: 1px solid #000000;
                            background-color: transparent;
                        }

                        label {
                            text-align: left;
                            float: left;
                            margin: 20px 0px 20px 5px!important;
                        }

                        textarea {
                            border-radius: 5px;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                        }

                        .btn-send {
                            margin-top: 50px;
                            padding: 20px 40px;
                            background-color: #d9d9d9;
                            border-radius: 50px;
                            color: #000000;
                            border: 0!important;

                            &:hover {
                                background-color: #AB3235;
                                color: #ffffff;
                            }
                        }
                    }

                }


            }

        
            .footer-wrapper {
                position: relative!important;
                width: 100vw!important;
                height: auto;
                min-height: 80px!important;
                margin: 0px;
                padding: 40px 5%;
                background-color: #484848;
                color: #ffffff;
        
                .footer-logo {
                    float: left;
                    height: 80px;
                }

                .socmed-container {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .socmed {
                        width: 50px;
                        padding: 10px;
                    }
                }

                .contact-details {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: left;
                    align-items: left;

                    .title {
                        display: block;
                        text-align: left;
                        color: #ffffff;
                        font-size: 1.5rem;
                        font-weight: 500;
                        letter-spacing: 2px;
                        
                    }

                    .details {
                        display: block;
                        text-align: left;
                        color: #ffffff;
                        font-size: 1rem;
                        font-weight: 200;
                        margin-top: 10px;
                    }
                }
            }
        

        }

    }

   

}




@media screen and (max-width: 500px) {
    
    body {
        background-repeat: no-repeat!important;
    }

    .main-wrapper {
        overflow-x: hidden!important;

        .header-wrapper {
            .temp-logo-container {
                .header-logo {
                    height: 80px!important;
                }
            }
        }

        .content-wrapper {
            .fullwidth {
                margin-top: 10px;
            }

            .page-wrapper {
                position: relative;
                width: 100vw!important;
                min-width: 100vw!important;
                min-height: 100vmin!important;

                .counter-container {
                    position: relative;
                    width: 100vw!important;
                    height: auto!important;
                    max-height: 600px!important;
                    background: url('../img/mp-big-red.png');
                    background-position: center;
                    background-size: cover!important;
                    background-repeat: no-repeat;
                   
                    .mp-container {
                        position: absolute;
                        top:20%!important;
                        left: 0%!important;
                        transform: translate(0%,-20%)!important;
                        width: 30px!important;

                        .megaphone {
                            height: 10px!important;
                        }

                    }

                    .counter {
                        position: absolute;
                        top: 20%!important;
                        right: 0%!important;
                        width: 70%!important;
                        transform: translate(0%, -20%)!important;
                        height: auto!important;
                        padding: 0px!important;

                        h1 {
                            font-size: 1.5rem!important;
                            color: #ffffff;
                        }

                        .expired-notice {
                            padding: 1rem;
                            width: auto;

                            span {
                                font-size: 0.7rem;
                                color: #ffffff;
                            }

                            p {
                                font-size: 0.6rem;
                                color: #ffffff;
                            }
                        }

                        .show-counter {
                            width: auto;
                            height: auto;

                            .countdown {
                                line-height: 1.25rem;
                                padding: 0 0.75rem;
                                align-items: center;
                                display: flex;
                                flex-direction: column;
                                border: 2px solid #ffffff;

                                p {
                                    margin: 0;
                                }
                            }

                        }
                    }

                    
                }

                .page-article {
                    width: 100vw;
                    padding: 80px 20% 80px 20%!important;
                    text-align: center;

                    h2 {
                        font-size: 1.2rem!important;
                        color: #70787C;
                        letter-spacing: 0px!important;
                        line-height: 1.5rem!important;
                    }

                    span {
                        font-size: 1rem!important;
                        color: #000000;
                        letter-spacing: 1px;
                        float: none;
                        vertical-align: middle;
                        text-align: center!important;
                    }

                    .qr-code {
                        float: none!important;
                        width: 150px;
                    }
                    
                }
            }

            .contact-page {
                position: relative;
                width: 100vw;
                padding: 100px 5% 80px 5%!important;
                text-align: center;
                margin: 0!important;
                
                .white-overlay {
                    z-index: -1;
                    position: absolute;
                    top: 0;
                   
                    right: 0;
                    width: 100vw;
                    height: 100%!important;
                    background-color: #ffffff;
                    opacity: 0.4;

                }

                .contact-container {
                    z-index: 999999;
                    width: 100%!important;
                    height: auto!important;
                    text-align: center;

                    p {
                        margin-bottom: 100px;
                    }

                    .contact-form {
                        padding: 0!important;

                        input[type="text"], input[type="email"] {
                            border-radius: 0;
                            border-top: 0;
                            border-right: 0;
                            border-left: 0;
                            padding-top: 20px;
                            border-bottom: 1px solid #000000;
                            background-color: transparent;
                        }

                        label {
                            text-align: left;
                            float: left;
                            margin: 20px 0px 20px 5px!important;
                        }

                        textarea {
                            border-radius: 5px;
                            background-color: #ffffff;
                            border: 1px solid #000000;
                        }

                        .btn-send {
                            margin-top: 50px;
                            padding: 20px 40px;
                            background-color: #d9d9d9;
                            border-radius: 50px;
                            color: #000000;
                            border: 0!important;

                            &:hover {
                                background-color: #AB3235;
                                color: #ffffff;
                            }
                        }
                    }

                }


            }

        
            .footer-wrapper {
                position: relative!important;
                width: 100vw!important;
                height: auto;
                min-height: 80px!important;
                margin: 0px;
                padding: 40px 5%;
                background-color: #484848;
                color: #ffffff;
        
                .footer-logo {
                    float: none!important;
                    display: block;
                    margin: 0 auto !important;
                    height: 80px;
                    
                }

                .socmed-container {
                    margin-top: 40px!important;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .socmed {
                        width: 50px;
                        padding: 10px;
                    }
                }

                .contact-details {
                    margin-top: 40px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .title {

                        display: block;
                        text-align: center;
                        color: #ffffff;
                        font-size: 1.5rem;
                        font-weight: 500;
                        letter-spacing: 2px;
                        
                    }

                    .details {
                        display: block;
                        text-align: center;
                        color: #ffffff;
                        font-size: 1rem;
                        font-weight: 200;
                        margin-top: 10px;
                    }
                }
            }            
        }
    }
}